import userService from "@/service/api/userService";

const state = {
  userInfo: {
    email: "",
  },
  userMessageDetail: {},
};

const actions = {
  async setUserInfo(context, payload) {
    context.commit("SET_USER_INFO", payload);
  },
  loginOut(context, payload) {
    context.commit("LOGIN_OUT", payload);
  },
  getUserInfo(context) {
    userService.userInfo().then((res) => {
      context.commit("GET_USER_INFO", res);
    });
  },
};

const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = payload.userInfo;
    localStorage.setItem("f-token", payload["f-token"]);
  },
  GET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  LOGIN_OUT(state) {
    state.userInfo = {
      email: "",
    };
    localStorage.removeItem("f-token");
  },
};

const getters = {
  getUser(state) {
    return state.userInfo;
  },
  getUserMessageDetail(state) {
    return state.userMessageDetail;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
