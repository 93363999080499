import request from "@/utils/httpRequest";

export default {
  getNotify: function () {
    return request({
      url: "/front/bbs/website/webNotify/getNotify",
      method: "get",
    });
  },
};
