<template>
  <div>
    <navbar />

    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
// import { getUserMessage } from "@/services/api/login";
export default {
  name: "layout",
  components: {
    Navbar,
  },
  mounted() {
    // this.getIsAttention();
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
}
</style>
