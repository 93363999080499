<template>
  <div class="sidebar-wrapper">
    <el-scrollbar>
      <el-menu v-if="role === 'user'" :default-active="activeMenu">
        <router-link to="/userCenter/home">
          <el-menu-item index="/userCenter/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">我的主页</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/userInfo">
          <el-menu-item index="/userCenter/userInfo">
            <i class="el-icon-user-solid"></i>
            <span slot="title">账号信息</span>
          </el-menu-item>
        </router-link>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-order"></i>
            <span>我的帖子</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/myPost">
              <el-menu-item index="/userCenter/myPost"
                >我发表的帖子</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/myCollectedPost">
              <el-menu-item index="/userCenter/myCollectedPost"
                >我收藏的帖子</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <router-link to="/userCenter/myMessage">
          <el-menu-item index="/userCenter/myMessage">
            <i class="el-icon-chat-dot-round"></i>
            <span slot="title">我的消息</span>
          </el-menu-item>
        </router-link>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-s-finance"></i>
            <span>我的产品</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/myProduct">
              <el-menu-item index="/userCenter/myProduct"
                >已购产品</el-menu-item
              >
            </router-link>
            <!-- <router-link to="/userCenter/myService">
              <el-menu-item index="/userCenter/myService"
                >订阅服务</el-menu-item
              >
            </router-link> -->
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-shopping-cart-2"></i>
            <span>出售应用</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/myPublishProduct">
              <el-menu-item index="/userCenter/myPublishProduct"
                >我上传的应用</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/myPublishCase">
              <el-menu-item index="/userCenter/myPublishCase"
                >我发布的案例</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>

      <el-menu v-else :default-active="activeMenu">
        <router-link to="/userCenter/admin/home">
          <el-menu-item index="/userCenter/admin/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">管理员控制台</span>
          </el-menu-item>
        </router-link>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-claim"></i>
            <span>待审核帖子</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/today/post">
              <el-menu-item index="/userCenter/admin/today/post"
                >今日新增帖子</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/today/message">
              <el-menu-item index="/userCenter/admin/today/message"
                >今日新增评论</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/audit/post">
              <el-menu-item index="/userCenter/admin/audit/post"
                >待审核帖子</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/audit/message">
              <el-menu-item index="/userCenter/admin/audit/message"
                >待审核评论</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>用户管理</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/user/list">
              <el-menu-item index="/userCenter/admin/auth/user/list"
                >用户管理</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/login/list">
              <el-menu-item index="/userCenter/admin/auth/login/list"
                >登录记录</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/access/list">
              <el-menu-item index="/userCenter/admin/auth/access/list"
                >访问记录</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/attack/list">
              <el-menu-item index="/userCenter/admin/auth/attack/list"
                >攻击行为</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/cooperation/list">
              <el-menu-item index="/userCenter/admin/auth/cooperation/list"
                >申请合作</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-s-shop"></i>
            <span>产品发货</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/product">
              <el-menu-item index="/userCenter/admin/auth/product"
                >产品发货</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/orderList">
              <el-menu-item index="/userCenter/admin/auth/orderList"
                >订单管理</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-s-release"></i>
            <span>待审核应用</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/audit/product">
              <el-menu-item index="/userCenter/admin/audit/product"
                >待审核应用</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/audit/case">
              <el-menu-item index="/userCenter/admin/audit/case"
                >待审核案例</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  props: ["role"],
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
  },
};
</script>

<style lang="less">
.el-scrollbar {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.sidebar-wrapper {
  width: 240px;
  min-height: calc(100vh - 61px);
  .el-menu,
  .el-scrollbar__view {
    height: 100% !important;
    background-color: #f5f7f9;
  }
  .el-submenu__title:hover {
    background-color: #e6e9eb;
  }
  .el-submenu {
    text-align: left;
  }
  .el-menu-item {
    text-align: left;
    &:focus {
      background-color: rgba(0, 0, 0, 0);
    }
    &:hover {
      background-color: #e6e9eb;
    }
  }
}
.el-submenu__title i {
  color: #909399 !important;
}
</style>
