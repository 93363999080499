import request from "@/utils/httpRequest";

export default {
  add: function (inputForm) {
    return request({
      url: "/front/bbs/website/accessHistory/add",
      method: "post",
      data: inputForm,
    });
  },
  list: function (params) {
    return request({
      url: "/front/bbs/website/accessHistory/list",
      method: "get",
      params: params,
    });
  },
};
