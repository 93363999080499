<template>
  <div class="header">
    <div class="header-inner">
      <div class="head-left-logo">
        <img
          height="40"
          src="@/assets/image/homePage/logo.png"
          alt="logo"
          @click="linkToHome"
          class="logo"
        />
      </div>

      <div class="app-router-link">
        <el-menu
          v-for="(item, i) in menuData"
          router
          :key="i"
          :default-active="activeIndex"
          class="el-menu-app"
          mode="horizontal"
          @select="handleMenuSelect"
        >
          <el-menu-item @click="scrollTop()" :index="item.linkTo">
            <router-link tag="li" :to="item.linkTo">
              <span>{{ item.name }}</span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="app-head-right">
        <el-link
          v-if="!currentUser.id"
          :underline="false"
          @click="$refs.loginPopup.init()"
          class="wx"
          ><WX class="wx-icon"></WX
        ></el-link>
        <div class="login_text" v-if="!currentUser.id">
          <router-link tag="li" :to="{ path: '/login' }">
            <el-button type="text">登录</el-button>
          </router-link>
          <span style="color: #7796ba"> | </span>
          <router-link tag="li" :to="{ path: '/register' }">
            <el-button type="text">注册</el-button>
          </router-link>
        </div>
        <div v-else class="already_login">
          <el-dropdown placement="bottom">
            <span class="el-dropdown-link">
              <el-avatar
                :size="28"
                :src="currentUser.avatar"
                style="position: relative; top: 7px; right: 5px"
              ></el-avatar>
              <span>
                {{
                  currentUser.email.startsWith("CS--")
                    ? currentUser.nickname
                    : currentUser.email
                }}
                |
                <span
                  class="vip"
                  v-if="currentUser && currentUser.title != '普通用户'"
                >
                  {{ currentUser.title }}
                </span>
                <span v-else class="not_vip">
                  {{ currentUser.title }}
                </span>
                |
                <span class="point">
                  <i class="el-icon-coin"> </i>
                  {{ currentUser.point }}</span
                >
              </span>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="u_info">
                  <div class="u_info_top">
                    <div class="u_info_top_name">
                      {{
                        currentUser.email.startsWith("CS--")
                          ? currentUser.nickname
                          : currentUser.email
                      }}
                    </div>
                    <div class="u_info_marker">
                      <div
                        class="login_out"
                        @click="openUserCenter('userInfo')"
                      >
                        修改信息
                      </div>
                      <div class="login_out" @click="loginOut">退出登录</div>
                    </div>
                  </div>
                  <div class="u_info_content">
                    <ul class="menu">
                      <li class="menu_item" @click="go('home', '我的主页')">
                        <div class="menu_item_label">
                          <i class="el-icon-s-home icon-color"></i>
                          <span>我的主页</span>
                        </div>
                      </li>
                      <li class="menu_item" @click="openUserCenter('myPost')">
                        <div class="menu_item_label">
                          <i class="el-icon-s-order icon-color"></i>
                          <span>我的帖子</span>
                        </div>
                      </li>
                      <li
                        class="menu_item"
                        @click="openUserCenter('myMessage')"
                      >
                        <div class="menu_item_label">
                          <i class="el-icon-chat-dot-round icon-color"></i>
                          <span>我的消息</span>
                        </div>
                        <el-badge
                          :value="currentUser.messageCount"
                          :max="99"
                          class="item"
                        ></el-badge>
                      </li>
                      <li
                        class="menu_item"
                        @click="go('myProduct', '我的产品')"
                      >
                        <div class="menu_item_label">
                          <i class="el-icon-s-finance icon-color"></i>
                          <span>我的产品</span>
                        </div>
                      </li>
                    </ul>
                    <!-- <div class="footer">
                      <div class="btn" @click="goToConsole('/buyer')">
                        去使用方控制台
                      </div>
                    </div> -->
                  </div>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <LoginPopup ref="loginPopup" />
  </div>
</template>

<script>
import { SelfElMessage, goToUserCenter } from "@/utils/utils";
import { getToken } from "@/service/token";
import loginService from "@/service/api/loginService";
import WX from "@/components/icon/WX.vue";
import LoginPopup from "@/components/login/LoginPopup";
export default {
  name: "Header",
  props: {
    msg: String,
  },
  components: {
    LoginPopup,
    WX,
    // EmailCode
  },
  data() {
    return {
      menuData: [
        { name: "首页", linkTo: "/home" },
        { name: "应用市场", linkTo: "/store" },
        { name: "系统演示", linkTo: "/demo" },
        { name: "文档", linkTo: "/document" },
        { name: "问答", linkTo: "/ask" },
        { name: "客户案例", linkTo: "/case" },
      ],
      activeIndex: "home",
      activeName: "user",
      flag: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (getToken()) {
        try {
          this.$store.dispatch("getUserInfo").then(() => {
            console.log("login success");
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    handleMenuSelect(key) {
      this.activeIndex = key;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    gotoAttestation() {
      if (!this.flag) {
        return;
      }
      this.flag = false;
    },
    loginOut() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginService.logout().then(() => {
            this.$store.dispatch("loginOut");
            this.$router.push({ path: "/home" });
            SelfElMessage({ type: "success", message: "退出登录成功" });
          });
        })
        .catch(() => {
          console.log("error!!");
        });
    },
    openUserCenter(path) {
      window.open(`/#/userCenter/${path}`);
    },
    go(type, menu) {
      const pre = `/#/userCenter/${type}`;
      let route = "";
      if (type == "buyer") {
        switch (menu) {
          case "订单管理":
            route = "/order/common";
            break;
          case "已购服务":
            route = "/purchased/myAPI";
            break;
        }
      } else if (type == "seller") {
        switch (menu) {
          case "订单管理":
            route = "/order/common";
            break;
        }
      }
      const url = pre + route;
      window.open(url);
    },
    goToConsole(path) {
      const userId = this.userDetail && this.userDetail.userId;
      goToUserCenter(path, userId);
    },
    linkToHome() {
      this.$router.push({ path: "/home" });
    },
    goDataRegister() {
      window.open("/#/userCenter/seller/data/dataRegister");
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    currentToken() {
      return getToken();
    },
    level() {
      const userInfo = this.$store.state.login.userInfo;
      let result = null;
      if (userInfo && userInfo.scoreLevel) {
        const num = userInfo.scoreLevel.replace(/\D/g, "");
        result = Number(num);
      }

      return result;
    },
    userDetail() {
      return this.$store.getters.getUserMessageDetail;
    },
    ShowDataAccession() {
      return this.$store.getters.getUserMessageDetail.sellerCertification
        ? true
        : false;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "~@/assets/css/default.less";
@downMenuTopBgColor: #f5f7f9;
.header {
  width: 100%;
  position: fixed;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
  .point {
    color: #ff5500 !important;
  }

  .wx {
    float: left;
    margin-right: 5px;
    line-height: 38px;
    .wx-icon {
      width: 1.5em;
      height: 1.5em;
      vertical-align: -0.4em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .vip {
    color: #fff;
    margin-left: 5px;
    padding-left: 5px;
    margin-right: 5px;
    background-image: linear-gradient(90deg, #fe8800 0%, #ff5500 100%);
  }
  .not_vip {
    color: #fff;
    background: #999;
  }
  .header-inner {
    .containerWidth;
    height: 80px;
    overflow: hidden;
  }
  .head-left-logo {
    float: left;
    line-height: 80px;
    img {
      position: relative;
      top: 15px;
      cursor: pointer;
    }
  }
  .app-router-link {
    float: left;
    height: 80px;
    ::v-deep .el-menu--horizontal {
      border-bottom: none;
      display: inline-block;
      .el-menu-item {
        height: 80px;
        line-height: 80px;
        transition: none;
        font-size: 16px;
        padding: 0 30px;
        font-weight: 400;
        span {
          color: #111;
          opacity: 0.4;
          &:hover {
            opacity: 1;
            color: #0089ff;
          }
        }

        .router-link-active span {
          color: #0089ff;
          opacity: 1;
          font-weight: 600;
        }
      }
      .el-menu-item.is-active {
        border-bottom: none;
      }
    }
  }

  .app-head-right {
    margin-top: 24px;
    float: right;
    display: flex;
    .login_text {
      line-height: 32px;
    }
    li {
      display: inline;
      cursor: pointer;
      color: #111111;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.el-dropdown-menu {
  padding: 0 !important;
}
.el-popper.el-dropdown-menu {
  ::v-deep .popper__arrow::after {
    border-bottom-color: @downMenuTopBgColor;
  }
}

::v-deep .el-tabs--border-card {
  border: none;
  > .el-tabs__content {
    padding: 0 !important;
  }
}

.el-badge__content--primary {
  background-color: @mainColor !important;
}
.already_login {
  ::v-deep .el-dropdown-link {
    cursor: pointer;
  }
  .level {
    position: absolute;
    left: 14px;
    bottom: -6px;
  }
  .name {
    margin-left: 8px;
  }
}
.u_info {
  min-width: 200px;

  .icon-color {
    font-size: 16px;
    color: #777 !important;
  }
  ::v-deep .el-tabs__nav {
    width: 100%;
    display: flex;
    .el-tabs__item {
      flex: 1;
      text-align: center;
      &.is-active {
        color: @mainColor !important;
      }
      &:hover {
        color: @mainColor !important;
      }
    }
  }
  &_top {
    padding: 20px 20px 10px 20px;
    background: @downMenuTopBgColor;
    &_name {
      cursor: pointer;
      font-size: 16px;
      color: #111111;
    }
    .u_info_marker {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      align-items: center;
      font-size: 12px;
      .marker {
        padding: 4px;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .login_out {
      font-size: 12px;
      opacity: 0.8;
      cursor: pointer;
      color: #666;
      line-height: 0.8;
      padding-bottom: 4px;
      border-bottom: 1px solid #666;
    }
  }
  &_content {
    ::v-deep .el-tabs__nav {
      transition: none;
    }
    ::v-deep .el-tabs__item {
      font-weight: 400;
    }
    ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
      border: 1px solid #e5e7e9;
      background-color: #fff;
    }
  }
  ::v-deep .menu {
    padding: 15px;
    min-height: 120px;
    &_item {
      padding: 10px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_label {
        font-size: 14px;
        span {
          display: inline-block;
          padding-left: 10px;
        }
      }
    }
  }
  .footer {
    border-top: 1px solid #dcdfe6;
    padding: 10px;
    .btn {
      border: 1px solid #3873ec;
      padding: 10px;
      width: 77.77%;
      margin: auto;
      background: #fff;
      border-radius: 20px;
      text-align: center;
      color: #3873ec;
      font-weight: 400;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        background: #3873ec;
        color: #fff;
      }
    }
  }
}
</style>
