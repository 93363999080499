

export class NetworkError extends Error {
  constructor(msg = "网络请求异常，请稍后重试!", response) {
    super();
    this.message = msg;
    this.response = response;
  }
  name = "NetworkError";
}


