let _storage = localStorage;

const TOKEN = "f-token";
export const setStorage = (storage) => {
  _storage = storage;
};

export const setToken = (token) => {
  _storage.setItem(TOKEN, token);
};

export const getToken = () => {
  return _storage.getItem(TOKEN);
};

export const clearToken = () => {
  _storage.removeItem(TOKEN);
};
