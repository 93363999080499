import request from "@/utils/httpRequest";

export default {
  getCode: function () {
    return request({
      url: "/front/user/getCode",
      method: "get",
    });
  },
  login: function (data) {
    return request({
      url: "/front/user/login",
      method: "post",
      data: data,
    });
  },
  logout: function () {
    return request({
      url: "/front/user/logout",
      method: "get",
    });
  },
  register: function (data) {
    return request({
      url: "/front/user/reg",
      method: "post",
      data: data,
    });
  },

  getEmailCode: function (email) {
    return request({
      url: "/front/user/getEmailCode",
      method: "get",
      params: { email: email },
    });
  },
  checkEmailCode: function (params) {
    return request({
      url: "/front/user/checkEmailCode",
      method: "get",
      params: params,
    });
  },
  updatePasswordByEmail: function (params) {
    return request({
      url: "/front/user/updatePasswordByEmail",
      method: "get",
      params: params,
    });
  },
  checkEmail: function (email) {
    return request({
      url: "/front/user/checkEmail",
      method: "get",
      params: { email: email },
    });
  },
  checkNickname: function (nickname) {
    return request({
      url: "/front/user/checkNickname",
      method: "get",
      params: { nickname: nickname },
    });
  },
  unbind: function () {
    return request({
      url: "/front/user/unbind",
      method: "get",
    });
  },
  loginByQQ: function (params) {
    return request({
      url: "/front/user/qq/login",
      method: "get",
      params: params,
    });
  },
  loginByWx: function (params) {
    return request({
      url: "/front/user/wx/login",
      method: "get",
      params: params,
    });
  },
};
