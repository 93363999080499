import request from "@/utils/httpRequest";

export default {
  userInfo: function () {
    return request({
      url: "/front/user/info",
      method: "get",
    });
  },

  userMessage: function () {
    return request({
      url: "/front/user/message",
      method: "get",
    });
  },

  getUserById: function (id) {
    return request({
      url: "/front/user/getUserById",
      method: "get",
      params: {
        id: id,
      },
    });
  },
  list: function (params) {
    return request({
      url: "/front/user/list",
      method: "get",
      params: params,
    });
  },
  updateUserInfo: function (inputForm) {
    return request({
      url: "/front/user/updateUserInfo",
      method: "post",
      data: inputForm,
    });
  },
  rePass: function (params) {
    return request({
      url: "/front/user/rePass",
      method: "get",
      params: params,
    });
  },
  audit: function (params) {
    return request({
      url: "/front/user/audit",
      method: "get",
      params: params,
    });
  },
  sendEmail: function (ids) {
    return request({
      url: "/front/user/sendEmail",
      method: "get",
      params: { ids: ids },
    });
  },
  signIn: function () {
    return request({
      url: "/front/user/sign/in",
      method: "get",
    });
  },
  signStatus: function () {
    return request({
      url: "/front/user/sign/status",
      method: "get",
    });
  },
  unbindQQ: function () {
    return request({
      url: "/front/user/qq/unbind",
      method: "get",
    });
  },
  unbindWx: function () {
    return request({
      url: "/front/user/wx/unbind",
      method: "get",
    });
  },
  bindEmail: function (inputForm) {
    return request({
      url: "/front/user/email/bind",
      method: "post",
      data: inputForm,
    });
  },
  unbindEmail: function () {
    return request({
      url: "/front/user/email/unbind",
      method: "get",
    });
  },
};
