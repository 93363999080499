<template>
  <div class="layout">
    <Header />
    <el-main class="main">
      <router-view />
    </el-main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
  props: {
    msg: String,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.layout {
  .main {
    min-height: calc(100vh - 323px);
    padding: 80px 0 0 0;
  }
}
</style>
