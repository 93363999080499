import request from "@/utils/httpRequest";

export default {
  queryById: function (id) {
    return request({
      url: `/front/sys/dict/queryById`,
      method: "get",
      params: { id: id },
    });
  },

  list: function (params) {
    return request({
      url: "/front/sys/dict/type/list",
      method: "get",
      params: params,
    });
  },

  queryDictValue: function (id) {
    return request({
      url: `/front/sys/dict/queryDictValue`,
      method: "get",
      params: { dictValueId: id },
      loading: false,
    });
  },

  getDictValue: function (dictTypeId) {
    return request({
      url: "/front/sys/dict/getDictValue",
      method: "get",
      params: {
        dictTypeId: dictTypeId,
      },
    });
  },

  getDictMap: function (dictTypeId) {
    return request({
      url: "/front/sys/dict/getDictMap",
      method: "get",
      params: {
        dictTypeId: dictTypeId,
      },
    });
  },
};
