import request from "@/utils/httpRequest";

export default {
  publish: function (inputForm) {
    return request({
      url: "/front/bbs/blog/blogPost/publish",
      method: "post",
      data: inputForm,
    });
  },

  detail: function (id) {
    return request({
      url: "/front/bbs/blog/blogPost/detail",
      method: "get",
      params: { id: id },
    });
  },

  queryById: function (id) {
    return request({
      url: "/front/bbs/blog/blogPost/queryById",
      method: "get",
      params: { id: id },
    });
  },

  setRecommend: function (id, recommend) {
    return request({
      url: "/front/bbs/blog/blogPost/setRecommend",
      method: "get",
      params: { id: id, recommend: recommend },
    });
  },

  setLevel: function (id, level) {
    return request({
      url: "/front/bbs/blog/blogPost/setLevel",
      method: "get",
      params: { id: id, level: level },
    });
  },

  list: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/list",
      method: "get",
      params: params,
    });
  },

  todayList: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/admin/todayList",
      method: "get",
      params: params,
    });
  },

  adminList: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/admin/list",
      method: "get",
      params: params,
    });
  },
  adminAudit: function (auditForm) {
    return request({
      url: "/front/bbs/blog/blogPost/admin/audit",
      method: "get",
      params: auditForm,
    });
  },
  adminDel: function (ids) {
    return request({
      url: "/front/bbs/blog/blogPost/admin/delete",
      method: "delete",
      params: { ids: ids },
    });
  },
  topList: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/top/list",
      method: "get",
      params: params,
    });
  },
  rightList: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/right/list",
      method: "get",
      params: params,
    });
  },
  mine: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/mine",
      method: "get",
      params: params,
    });
  },
  listByUserId: function (params) {
    return request({
      url: "/front/bbs/blog/blogPost/listByUserId",
      method: "get",
      params: params,
    });
  },
};
