<template>
  <div class="footer">
    <div class="app-foot-inner">
      <div class="app-foot-content">
        <div class="app-main-content">
          <div style="margin-bottom: 25px">
            <div class="app-link-to">
              <span class="foot-content-title">友情链接</span>
              <span
                v-for="(item, index) in result.link.filter(
                  (r) => r.type === '3'
                )"
                class="foot-content-link"
                :key="index"
                @click="footLink(item.network)"
              >
                {{ item.name }}
              </span>
              <span
                class="foot-content-link"
                @click="
                  footLink(
                    'http://wpa.qq.com/msgrd?v=3&amp;uin=117575171&amp;site=qq&amp;menu=yes'
                  )
                "
              >
                申请友链
              </span>
            </div>
            <div class="app-link-to">
              <span class="foot-content-title">服务支持</span
              ><span
                class="foot-content-link"
                @click="openDialog('user_agreement')"
              >
                用户协议 </span
              ><span class="foot-content-link" @click="footLink('/ask')">
                常见问题 </span
              ><span class="foot-content-link" @click="footLink('/document')">
                在线文档
              </span>
            </div>
            <div class="app-link-to">
              <span class="foot-content-title">联系我们</span>
              <span class="foot-content-link">电话：18114800337</span>
              <span class="foot-content-link">邮箱:117575171@qq.com</span>
            </div>
          </div>
          <div class="app-qrCode">
            <img src="@/assets/image/footer/QRcode.png" alt="QRcode" />
            <label>扫描二维码，添加微信</label>
          </div>
        </div>
        <div class="divider-white"></div>
        <div class="divider-black"></div>

        <div class="copy-right">
          <span class="beian" @click="goLink">苏ICP备16002985号-1</span>
          版权所有 @2022 jeeplus.org. All Rights Reserved
        </div>
        <Agreement
          :modelValue="dialogVisible"
          @setDialogVisible="setDialogVisible"
        />
      </div>
    </div>
  </div>
</template>

<script>
import blogPostService from "@/service/api/bbs/blog/blogPostService";
const Agreement = () => import("@/views/agreement/index.vue");
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  components: {
    Agreement,
  },
  created() {
    this.init();
  },
  data() {
    return {
      result: {
        hotPostList: [],
        topUserList: [],
        link: [],
      },
      showDataCustom: false,
      dialogVisible: false,
    };
  },
  methods: {
    async init() {
      this.result = await blogPostService.rightList();
    },
    footLink: function (val) {
      if (!val) {
        return;
      }
      if (val.indexOf("http") == 0) {
        window.open(val);
      } else {
        this.$router.push(val);
      }
    },
    closeDataCustom() {
      this.showDataCustom = false;
    },
    setDialogVisible(flag) {
      this.dialogVisible = flag;
    },
    openDialog(val) {
      if (val == "data-custom") {
        this.showDataCustom = true;
      } else if (val == "user_agreement") {
        this.setDialogVisible(true);
      }
    },
    goLink: function () {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "~@/assets/css/default.less";
.footer {
  overflow: hidden;
  background: #2d353d;
  height: 323px;

  .app-foot-inner {
    .containerWidth;
    padding: 0 10px;
    .app-foot-content {
      width: 100%;
      margin-top: 25px;
      .app-link-to {
        display: flex;
        padding: 15px 0;
        font-weight: 400;
        .foot-content-title {
          font-size: 14px;
          color: #ffffff;
          font-weight: 600;
          float: left;
          margin-right: 40px;
        }
        .foot-content-link {
          font-size: 14px;
          opacity: 0.4;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
        }
        .foot-content-about {
          font-size: 14px;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
        }
        .foot-content-info {
          margin-right: 30px;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .app-main-content {
        position: relative;
        .link-items {
          display: block;
          .app-link-to;
        }
        .app-qrCode {
          position: absolute;
          right: 10px;
          text-align: center;
          top: 12px;
          img {
            width: 100px;
            display: block;
            margin-bottom: 8px;
          }
          label {
            font-size: 14px;
            color: #fff;
          }
        }
      }
      .divider-white {
        height: 1px;
        width: 100%;
        opacity: 0.1;
        background: #ffffff;
      }
      .divider-black {
        height: 1px;
        width: 100%;
        opacity: 0.2;
        background: #000000;
      }
      .about-us {
        // margin: 15px 0;
      }
      .copy-right {
        text-align: center;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .beian {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
