import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../components/Layout.vue";
import UserCenterLayout from "../layout/userCenter";
import DocLayout from "../layout/doc/index.vue";
import accessHistoryService from "@/service/api/bbs/website/accessHistoryService";

// const originalPush = VueRouter.prototype.push;
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(VueRouter);

const routes = [
  {
    path: "/userCenter",
    name: "UserCenterLayout",
    component: UserCenterLayout,
    redirect: "/userCenter/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/user/home/Home.vue"
          ),
      },
      {
        path: "admin/home",
        name: "AdminHome",
        component: () =>
          import(
            /* webpackChunkName: "AdminHome" */
            "@/views/userCenter/admin/Home.vue"
          ),
      },
      {
        path: "admin/audit/post",
        name: "AuditPost",
        component: () =>
          import(
            /* webpackChunkName: "AuditPost" */
            "@/views/userCenter/admin/AuditPost.vue"
          ),
      },
      {
        path: "admin/audit/message",
        name: "AuditMessage",
        component: () =>
          import(
            /* webpackChunkName: "AuditMessage" */
            "@/views/userCenter/admin/AuditComment.vue"
          ),
      },
      {
        path: "admin/today/post",
        name: "TodayPost",
        component: () =>
          import(
            /* webpackChunkName: "AuditPost" */
            "@/views/userCenter/admin/TodayPost.vue"
          ),
      },
      {
        path: "admin/today/message",
        name: "TodayMessage",
        component: () =>
          import(
            /* webpackChunkName: "AuditMessage" */
            "@/views/userCenter/admin/TodayComment.vue"
          ),
      },
      {
        path: "admin/deliver/product",
        name: "DeliverProduct",
        component: () =>
          import(
            /* webpackChunkName: "DeliverProduct" */
            "@/views/userCenter/admin/DeliverProduct.vue"
          ),
      },
      {
        path: "admin/audit/product",
        name: "AuditProduct",
        component: () =>
          import(
            /* webpackChunkName: "AuditProduct" */
            "@/views/userCenter/admin/AuditProduct.vue"
          ),
      },
      {
        path: "admin/audit/case",
        name: "AuditCase",
        component: () =>
          import(
            /* webpackChunkName: "AuditProduct" */
            "@/views/userCenter/admin/AuditCase.vue"
          ),
      },

      {
        path: "admin/auth/product",
        name: "AuthProduct",
        component: () =>
          import(
            /* webpackChunkName: "AuditProduct" */
            "@/views/userCenter/admin/auth/UserProduct.vue"
          ),
      },

      {
        path: "admin/auth/orderList",
        name: "OrderList",
        component: () =>
          import(
            /* webpackChunkName: "AuditProduct" */
            "@/views/userCenter/admin/auth/OrderList.vue"
          ),
      },

      {
        path: "admin/auth/product/send",
        name: "SendProduct",
        component: () =>
          import(
            /* webpackChunkName: "UserProductForm" */
            "@/views/userCenter/admin/auth/UserProductForm.vue"
          ),
      },
      {
        path: "admin/auth/user/list",
        name: "UserList",
        component: () =>
          import(
            /* webpackChunkName: "UserList" */
            "@/views/userCenter/admin/user/UserList.vue"
          ),
      },
      {
        path: "admin/auth/access/list",
        name: "AccessHistoryList",
        component: () =>
          import(
            /* webpackChunkName: "AccessHistoryList" */
            "@/views/userCenter/admin/user/AccessHistoryList.vue"
          ),
      },
      {
        path: "admin/auth/attack/list",
        name: "AttackList",
        component: () =>
          import(
            /* webpackChunkName: "AttackList" */
            "@/views/userCenter/admin/user/AttackList.vue"
          ),
      },
      {
        path: "admin/auth/cooperation/list",
        name: "CooperationList",
        component: () =>
          import(
            /* webpackChunkName: "CooperationList" */
            "@/views/userCenter/admin/user/CooperationList.vue"
          ),
      },
      {
        path: "admin/auth/login/list",
        name: "LoginHistoryList",
        component: () =>
          import(
            /* webpackChunkName: "LoginHistoryList" */
            "@/views/userCenter/admin/user/LoginHistoryList.vue"
          ),
      },
      {
        path: "myPost",
        name: "MyPost",
        component: () =>
          import(
            /* webpackChunkName: "MyPost" */ "@/views/userCenter/user/post/MyPost.vue"
          ),
      },
      {
        path: "myMessage",
        name: "MyMessage",
        component: () =>
          import(
            /* webpackChunkName: "MyMessage" */ "@/views/userCenter/user/MyMessage.vue"
          ),
      },
      {
        path: "myCollectedPost",
        name: "MyCollectedPost",
        component: () =>
          import(
            /* webpackChunkName: "MyCollectedPost" */ "@/views/userCenter/user/post/MyCollectedPost.vue"
          ),
      },
      {
        path: "myProduct",
        name: "MyProduct",
        component: () =>
          import(
            /* webpackChunkName: "MyProduct" */ "@/views/userCenter/user/auth/MyProduct.vue"
          ),
      },
      {
        path: "myGencode",
        name: "MyGencode",
        component: () =>
          import(
            /* webpackChunkName: "MyProduct" */ "@/views/userCenter/user/auth/MyGencodeList.vue"
          ),
      },
      {
        path: "myPublishProduct",
        name: "MyPublishProduct",
        component: () =>
          import(
            /* webpackChunkName: "MyPublishProduct" */ "@/views/userCenter/user/product/MyPublishProduct.vue"
          ),
      },
      {
        path: "newProduct",
        name: "NewProduct",
        component: () =>
          import(
            /* webpackChunkName: "NewProduct" */ "@/views/userCenter/user/product/form/NewProduct.vue"
          ),
      },
      {
        path: "newCase",
        name: "NewCase",
        component: () =>
          import(
            /* webpackChunkName: "NewProduct" */ "@/views/userCenter/user/case/NewCase.vue"
          ),
      },
      {
        path: "form/historyList",
        name: "HistoryList",
        component: () =>
          import(
            /* webpackChunkName: "HistoryList" */ "@/views/userCenter/user/product/form/HistoryList.vue"
          ),
      },
      {
        path: "admin/newProduct",
        name: "AdminNewProduct",
        component: () =>
          import(
            /* webpackChunkName: "AdminNewProduct" */ "@/views/userCenter/user/product/form/NewProduct.vue"
          ),
      },
      {
        path: "myPublishCase",
        name: "MyPublishCase",
        component: () =>
          import(
            /* webpackChunkName: "MyPublishCase" */ "@/views/userCenter/user/case/MyPublishCase.vue"
          ),
      },
      {
        path: "userInfo",
        name: "UserInfo",
        component: () =>
          import(
            /* webpackChunkName: "UserInfo" */ "@/views/userCenter/user/UserInfo.vue"
          ),
      },
      {
        path: "*",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/404.vue"
          ),
      },
    ],
  },
  {
    path: "/doc",
    name: "docLayout",
    component: DocLayout,
    redirect: "/doc/detail/:rootId/:childId",
    children: [
      {
        path: "detail/:rootId/:childId",
        name: "docDetail",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/doc/detail/index.vue"
          ),
      },
      {
        path: "*",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/404.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "home",
    children: [
      {
        path: "/",
        redirect: "home",
      },
      {
        path: "home",
        name: "HomePage",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */
            "@/views/homePage/index.vue"
          ),
      },
      {
        path: "user/home/:id",
        name: "UFHome",
        component: () =>
          import(
            /* webpackChunkName: "UFHome" */
            "@/views/userCenter/front/Home.vue"
          ),
      },
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/login/index.vue"),
      },
      {
        path: "getWxInfo",
        name: "getWxInfo",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/login/wxLogin.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "Register" */
            "@/views/register/index.vue"
          ),
      },
      {
        path: "register/qq",
        name: "RegisterQQ",
        component: () =>
          import(
            /* webpackChunkName: "Register" */
            "@/views/register/qq.vue"
          ),
      },
      {
        path: "register/wx",
        name: "RegisterWX",
        component: () =>
          import(
            /* webpackChunkName: "Register" */
            "@/views/register/wx.vue"
          ),
      },
      {
        path: "forgetPassword",
        name: "ForgetPassword",
        component: () =>
          import(
            /* webpackChunkName: "ForgetPassword" */
            "@/views/forgetPassword/index.vue"
          ),
      },
      {
        path: "getQQInfo",
        name: "getQQInfo",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/login/qqLogin.vue"),
      },
      {
        path: "demo",
        name: "Demo",
        component: () =>
          import(
            /* webpackChunkName: "Demo" */
            "@/views/demo/index.vue"
          ),
      },
      {
        path: "ask",
        name: "Ask",
        redirect: "/ask/list",
        component: () =>
          import(
            /* webpackChunkName: "Ask" */
            "@/views/ask/index.vue"
          ),

        children: [
          {
            path: "list",
            name: "AskList",
            component: () =>
              import(
                /* webpackChunkName: "AskList" */
                "@/views/ask/list.vue"
              ),
          },
          {
            path: "detail/:id",
            name: "AskDetail",
            component: () =>
              import(
                /* webpackChunkName: "AskDetail" */
                "@/views/ask/detail/index.vue"
              ),
          },
          {
            path: "new",
            name: "NewPost",
            component: () =>
              import(
                /* webpackChunkName: "NewDataTrader" */
                "@/views/ask/new/index.vue"
              ),
          },
          {
            path: "new/detail/:id",
            name: "EditPost",
            component: () =>
              import(
                /* webpackChunkName: "NewDataTrader" */
                "@/views/ask/new/index.vue"
              ),
          },
        ],
      },
      {
        path: "document",
        name: "Doc",
        component: () =>
          import(
            /* webpackChunkName: "Doc" */
            "@/views/doc/index.vue"
          ),
      },
      {
        path: "store",
        name: "Store",
        component: () =>
          import(
            /* webpackChunkName: "Store" */
            "@/views/store/index.vue"
          ),
      },
      {
        path: "case",
        name: "Case",
        component: () =>
          import(
            /* webpackChunkName: "Case" */
            "@/views/case/index.vue"
          ),
      },
      {
        path: "case/detail",
        name: "CaseDetail",
        component: () =>
          import(
            /* webpackChunkName: "CaseDetail" */
            "@/views/case/detail/index.vue"
          ),
      },
      {
        path: "store/detail",
        name: "StoreDetail",
        component: () =>
          import(
            /* webpackChunkName: "StoreDetail" */
            "@/views/store/detail/index.vue"
          ),
      },
      {
        path: "store/order",
        name: "StoreOrder",
        component: () =>
          import(
            /* webpackChunkName: "StoreOrder" */
            "@/views/store/order/index.vue"
          ),
      },
      {
        path: "*",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/404.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    sessionStorage.getItem("jp-access") !== "1" &&
    (from.name == null || to.query.from != undefined)
  ) {
    accessHistoryService.add({
      referUrl: document.referrer,
      ad: to.query.from,
    });

    sessionStorage.setItem("jp-access", "1");
  }
  next();
});

export default router;
